<template>
    <div class="scanPreResult" v-loading="loading">
        <el-row style="margin-top: 10px; margin-bottom: 10px;">
            <div class="search_container searchArea">
                <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                    <el-form-item label="班级"  prop="hardId" v-if="userInfo.role=='teacher'">
                        <el-select v-model="searchForm.hardId" filterable placeholder="请选择" @change="hardChange">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="试卷列表：" prop="examId" >
                        <el-select v-model="searchForm.examId" filterable placeholder="请选择" remote :remote-method="remoteExam" @change="examChange" style="min-width: 300px;" >
                            <!--                        <el-option key="" label="全部" value=""></el-option>-->
                            <el-option v-for="item in examList" :key="item.id" :label="item.name+'('+(item.id||item.exam_id)+')'" :value="item.id||item.exam_id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="查找">
                        <el-input v-model="searchForm.searchText" placeholder="学号、姓名"  @keyup.enter.native='searchRightList'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="info" size ="mini" icon="search" @click='searchRightList'>查询</el-button>
                    </el-form-item>
                    <el-form-item v-if="rightList && rightList.length > 0">
                        人数：{{searchForm.totalCount}}
                    </el-form-item>
                </el-form>
            </div>
        </el-row>

        <el-row v-if="rightList && rightList.length > 0">
            <el-col :span="4" style="padding-left: 10px; padding-right: 10px;">
                <div style="padding: 12px;">
                  <el-select v-model="searchForm.examHardId" filterable placeholder="请选择" @change="searchRightList">
                    <el-option :key="null" label="全部" :value="null"></el-option>
                    <el-option v-for="item in examHardClassList" :key="item.hard_id" :label="item.hard_name+'('+item.cou+')'" :value="item.hard_id">
                    </el-option>
                  </el-select>
                </div>
                <div class="left-part" :style="leftStyle" >
                    <el-row class="data-row"  v-for="(item, index) in rightList">
                        <el-col :span="24"  class="student-no-lost">
                            <el-link type="primary" @click="openRight(item)">{{item.student_name}}({{item.student_no}})</el-link>
                        </el-col>
                    </el-row>
                    <el-row class="page-row">
                        <el-col :span="8">
                            <el-button type="text" size="mini" @click='loadByPage(-1)'>上一页</el-button>
                        </el-col>
                        <el-col :span="8">
                            {{searchForm.pageNo}}/{{parseInt(searchForm.totalCount/searchForm.pageSize)+1}}
                        </el-col>
                        <el-col :span="8">
                            <el-button type="text" size="mini" @click='loadByPage(1)'>下一页</el-button>
                        </el-col>
                    </el-row>
                </div>

            </el-col>
            <el-col :span="20" >
                <rightPage v-if="selRight!=null" ref="rightPage" :info="selRight" @ok="examChange" @changeExam="examChange"></rightPage>
            </el-col>
        </el-row>


    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";
    import rightPage from "./rightPage"
    import XEUtils from "xe-utils";
    import Pagination from "@/components/pagination";

    export default {
        name: 'scanPreResult',
        data(){
            return {
                tableData: [], tableHeight:0, examList: [],
                loading:false, queryExamId: null, queryStudentNo: null,
                selRight: null, hardClassList: [], examHardClassList: [],
                rightList: [], leftStyle: null, examId: null,
              examForm: { id: null, searchText: null, },
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, hardId: null, examId: null, examHardId: null,  },
            }
        },
        components:{
            Pagination, rightPage,
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        created() {
        },
      	mounted() {
          console.log("userInfo", this.userInfo)
            let myHeight = document.documentElement.clientHeight ;
            this.loadHMyClassSelectList();
            this.leftStyle = {height: (myHeight-250) + 'px'};
            let queryExamId = this.$route.query.e;
            if(queryExamId && queryExamId != null) this.queryExamId = XEUtils.toNumber(queryExamId); else this.queryExamId = null;
            let queryStudentNo = this.$route.query.sn;
            if(queryStudentNo && queryStudentNo != null) this.queryStudentNo = queryStudentNo; else this.queryStudentNo = null;
            let that = this;
            this.loadExamList(function () {
                if(that.queryExamId != null && that.queryStudentNo){
                    that.searchForm.pageNo = 1;
                    that.searchForm.examId = that.queryExamId; that.searchForm.searchText = that.queryStudentNo;
                    that.loadRightList(function (myList) {
                        if(myList && myList.length > 0){
                            that.openRight(myList[0]);
                        }
                    });
                }

            });
	   },
        methods: {

            formatStatus(status){
                return mUtils.examStatus(status);
            },
            getExamName(index, item){
                if(item.student_no != null){
                    return index + "." + item.student_no + "(" + item.seq + ")";
                }
                return index + "." + mUtils.strLeft(item.exam_name, 15) + "(" + item.seq + ")";
            },
            loadHMyClassSelectList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'teacherMyClass', methodName: '/myClassSelect',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0) {
                        this.hardClassList = data;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            examChange(v){
                this.loading = true;
                this.setSelNull();
                this.loadExamHardList();
                this.loadRightList();
            },
            hardChange(v){
                if(v == "") this.loadExamList();
                else this.loadMyClassExams(v);
            },
            loadMyClassExams(hardId){
                this.examList = [];
                let param = { controllerName: 'teacherMyClass', methodName: '/myClassExams', pageNo: 1, pageSize: 5000, param: { hardClassId: hardId}};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (code == 0) {
                        this.examList = data;
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            loadExamList(callback){
                let search = { licenceId: this.userInfo.licenceId, isAb: '1', schoolId: null, teacherId: null, searchText: mUtils.searchText(this.examForm.searchText)};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 1, pageSize: 50, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            remoteExam(query){
              if(query !== '' && query.length > 1){
                console.log("remoteExam", query);
                this.examForm.id = null;
                this.examForm.searchText = query;
                this.examList = [];
                this.loadExamList();
              }
            },
            openRight(row){
                this.selRight = row;
            },
            loadByPage(i){
                if(i<0 && this.searchForm.pageNo < 2) return;
                if(i>0 && this.searchForm.pageNo > parseInt(this.searchForm.totalCount/this.searchForm.pageSize)) return;
                this.searchForm.pageNo = this.searchForm.pageNo + i;
                this.loadRightList();
            },
            searchRightList(){
                this.searchForm.pageNo = 1;
                this.selRight = null;
                this.loadRightList();
            },

            loadExamHardList(){
              let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.searchForm.examId), orderBy: null};
              let param = { controllerName: 'exam/kg', methodName: '/hardClassList',  param: search};
              return simpleApi.post(param).then(({result: {code, data}}) =>  {
                console.log("loadRightList", data)
                if (code == 0) {
                  this.examHardClassList = data;
                }
              }).catch((error) => {
                console.log("error", error)
              });
            },
            loadRightList(callback){
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.searchForm.examId),
                    searchText: mUtils.searchText(this.searchForm.searchText), hardId: this.searchForm.examHardId, orderBy: 'sf.student_no'};
                let param = { controllerName: 'scan/scanPreResult', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadRightList", data)
                    if (data.code == 0) {
                        this.rightList = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                        if(callback) callback(this.rightList);
                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log("error", error)
                });
            },

            loadScanCount(){

                let search = { licenceId: this.userInfo.licenceId, examId: this.examId};
                let param = { controllerName: 'scan/scanPreResult', methodName: '/scanCount', param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data) {


                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            setSelNull(){
                this.selRight = null;
            },



        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .scanPreResult{
        padding: 10px;
        .search_container{

        }
        .error {
            color: red;
        }
        .collapse-title{
            font-size: 16px;
            font-weight: bold;
            margin-left: 20px;
        }
        .left-part {
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            overflow: auto;
            .data-row{
                margin-bottom: 10px;
                text-align: left;
            }
            .page-row{
                margin-top: 10px;
                line-height: 30px;
                text-align: center;
            }
        }
        .right-part {
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            .data-row{
                margin-bottom: 10px;
            }
            .student-no-lost {
                text-align: left;
            }
        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
           width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }

</style>


